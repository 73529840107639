<template lang="html">
  <v-app>
    <Snackbar />
    <v-main>
      <v-container class="fill-height">
        <v-row align="center" justify="center">
          <v-img src="@/assets/mangbagja500px.png" alt="" height="150" class="animate__animated animate__bounceIn" contain/>
          <v-col cols="12" class="d-flex flex-column align-center justify-center">
            <v-carousel
              cycle
              height="250"
              hide-delimiter-background
              show-arrows-on-hover
              :show-arrows="false"
              hide-delimiters
            >
              <template v-for="(slide,i) in quotes">
                <v-carousel-item
                  :key="i"
                  @click="newTabLink(slide.to)"
                  v-if='slide.type=="quote"'
                >
                  <v-sheet
                    color="grey lighten-3"
                    height="100%"
                  >
                    <v-row
                      align="center"
                      justify="center"
                    >
                      <div class="display-2 pa-5" style="text-align:center">{{ slide.content }}</div>
                    </v-row>
                  </v-sheet>
                </v-carousel-item>
                <v-carousel-item
                  v-else
                  @click="newTabLink(slide.to)"
                  :key="i"
                  :src="slide.content"
                  contain
                ></v-carousel-item>
              </template>
            </v-carousel>
          </v-col>
        </v-row>
        <!-- <v-row align="center" justify="center">
          <v-col cols="12" class="d-flex flex-column align-center justify-center">
            <v-img src="@/assets/mangbagja500px.png" alt="" height="100" class="animate__animated animate__bounceIn" contain/>
            <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            <span class="subtitle-1 font-weight-light" >{{quote}}</span>
          </v-col>
        </v-row> -->
      </v-container>
    </v-main>
    <v-btn
      @click="logout()"
      class="ma-5"
      color="primary"
      fab
      dark
      absolute
      right
      title="keluar"
    >
      <v-icon>mdi-logout</v-icon>
    </v-btn>
  </v-app>

</template>

<script>
import 'animate.css'
import router from '@/router'
import store from '@/store'
import Snackbar from '@/components/common/Snackbar'
export default {
  components:{
    Snackbar
  },
  data(){
    return {
      quotes:[
        {content: "“Datang bersama merupakan awal perjalanan, tetap bersama merupakan kemajuan. Bekerja sama dalam tim adalah kesuksesan.” ― Henry Ford", type:"quote"},
        {content: "“Struggle that you do today is the single way to build a better future.”", type:"quote"},
        {content: "“Orang hebat tidak dihasilkan dari kemudahan, kesenangan, dan kenyamanan. Mereka dibentuk melalui kesulitan, tantangan, dan air mata.” ― Dahlan Iskan", type:"quote"} ,
        {content: "“Develop a passion for learning, if you do you'll never cease to grow.”", type:"quote"},
        {content: "“Kepanikan adalah separuh penyakit. Ketenangan adalah separuh obat, kesabaran adalah permulaan kesembuhan.” ― Ibnu Sina", type:"quote"},
        {content: "“Find a group of people who challenge and inspire you, spend a lot of time with them, and it will change your life. ― Amy Poehler”", type:"quote"},
        {content: "“Keberhasilan bukanlah milik orang yang pintar. Keberhasilan adalah kepunyaan mereka yang senantiasa berusaha.” ― B.J. Habibie", type:"quote"},
        {content: "“Kadang kita terlalu sibuk memikirkan kesulitan-kesulitan sehingga kita tidak punya waktu untuk mensyukuri rahmat Tuhan.” ― Jenderal Sudirman", type:"quote"},
        {content: "“Kurang cerdas dapat diperbaiki dengan belajar. Kurang cakap dapat dihilangkan dengan pengalaman. Namun tidak jujur itu sulit diperbaiki.” ― Mohammad Hatta", type:"quote"},
        {content: "“Jangan takut jatuh, kerana yang tidak pernah memanjatlah yang tidak pernah jatuh. Yang takut gagal, kerana yang tidak pernah gagal hanyalah orang-orang yang tidak pernah melangkah.” ― Buya Hamka", type:"quote"},
        // {content: "“Kecantikan yang abadi terletak pada keelokan adab dan ketinggian ilmu seseorang. Bukan terletak pada wajah dan pakaiannya.” ― Hamka", type:"quote"},
      ],
      // quote:"“Kecantikan yang abadi terletak pada keelokan adab dan ketinggian ilmu seseorang. Bukan terletak pada wajah dan pakaiannya.” ― Hamka"
    }
  },
  created(){
    this.createRoute()
  },
  methods:{
    async createRoute(){
      console.log('calling create route')
      // buat route aplikasi
      await store.dispatch('routes/generateRoutes')
      // route ke dashboard
      router.push('/dashboard')
    },
    logout(){
      store.commit('user/SET_DIRECT', false)
      store.dispatch('user/logout')
    }
  }
}
</script>

<style lang="css" scoped>
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #aaa;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

</style>
